<template>
  <div>
    <v-dialog 
      fullscreen 
      hide-overlay 
      transition="slide-full-modal-transition" 
      v-model="formOpened" content-class="j_modal" persistent>
      <v-card>
        <div class="modal__header full_title pd_left_3">
          <button class="hamberger_menu" type="button"  @click="onAction('cancel')">
            <v-icon>mdi-menu</v-icon>
          </button>
          <span>SQL Queries <span class="sub_title">{{ `(${item.idx}) ` }} {{ item.name || '' }}</span></span>
        </div>

        <v-card-text class="modal__formControl dark_theme properties2 type2">
          <!-- <v-form v-model="valid" ref="form"> -->
            <v-container>
              <v-card-actions class="modal_toolbar">
                <div class="tool_menus">
                  <div 
                    data-common-quickbtn='normal'
                    title="general"
                    :class="typeName == __C_.DATATABLE.TYPE_CODE_GENERAL ? 'selected': ''"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </div>
                  <div 
                    data-pie-quickbtn="timeline-extended" 
                    title="timeline-extended"
                    :class="typeName == __C_.DATATABLE.TYPE_CODE_TIMELINE ? 'selected': ''"
                  ></div>
                </div>
              </v-card-actions>
              <div class="modal_dark_btn extra_properties_summary">
                <j-button
                  class="type01 sky  type_full "
                  :class="{ disabled: !resetable }"
                  :disabled="!resetable"
                  @click="onAction('save')"
                >Save</j-button>
                <j-button v-if="modeMod" class="type01 delete  type_full" @click="onAction('delete')">Delete</j-button>
                <div class="check_wrapper">
                  <v-checkbox v-model="update" :label="'Update'"/>
                  <v-checkbox :label="'Page Svg'" />
                  <v-checkbox :label="'Fixed'" />
                </div>
                <button class="help" @click="(e) => { helper=!helper }"></button>
              </div>

              <div class="modal__formControl_inside">
                <v-tabs v-model="stepper" class="wrap__modal_wfix">
                  <v-tab data-type="navigation" title="navigation"></v-tab>
                  <v-tab data-type="database" title="database"></v-tab>

                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-navigation ref="general" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-database ref="database" type="datatable" @save="onDBSave" />
                  </v-tab-item>
                </v-tabs>

                <div id="j-sql-query-tool">
                  
                  <div id="anotherPane" class="_query_tool_textarea_wrapper__">
                    <textarea v-model="localItem.qDataset" class="type_exp sans_serif_style" style="padding: 15px;" />
                  </div>

                  <div id="pane" class="_query_tool_compment_wrapper__">
                    <input v-model="datarows" class="datarows" type="text" />

                    <j-button 
                      class="writable validate"
                      @click="onValidate()"
                    >
                      <v-icon>mdi-arrange-bring-forward</v-icon>Validate
                    </j-button>

                    <j-button 
                      v-if="resultItemsAvailable"
                      class="writable tojson" 
                      @click="toJSON()"
                    >
                      <v-icon>mdi-arrange-bring-forward</v-icon>Copy JSON String
                    </j-button>

                    <j-button 
                      v-if="resultItemsAvailable"
                      class="writable tojs" 
                      @click="toJS()"
                    >
                      <v-icon>mdi-arrange-bring-forward</v-icon>Copy JS Object
                    </j-button>

                    <v-tabs v-model="stepperSqlTool" id="title">
                      <v-tab>Results</v-tab>
                      <v-tab>Message</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="stepperSqlTool">
                      <v-tab-item :transition="false" :reverse-transition="false">
                        <j-data-grid
                          v-model="resultSelected"
                          hide-actions
                          :headers="resultHeaders"
                          :items="resultItems"
                        >
                          <template v-slot:items="props">
                            <tr :active="props.selected" @click="propStatus(props)">
                              <td v-for="header in resultHeaders" :key="header.index">{{ props.item[header.value] }}</td>
                            </tr>
                          </template>
                        </j-data-grid>
                      </v-tab-item >
                      <v-tab-item :transition="false" :reverse-transition="false">
                        <div style="padding: 15px;" :class="{ invalid: !validated.valid }">
                          <pre>
                            {{ validated.message }}
                          </pre>
                        </div>
                      </v-tab-item>
                    </v-tabs-items>
                  </div>
                  <!-- <div id="ghostpane"></div>  -->
                </div>
                  <div v-if="helper" id="j-chart-helper">
                    <img :src="helperUrl" />
                  </div>
              </div>
            </v-container>
          <!-- </v-form> -->
        </v-card-text>
      </v-card>
    </v-dialog>
    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :title-description="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :button-text="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapMutations, mapGetters } from "vuex"
import { ChartLibraryService, DBSupportService } from "@/services"
import { QueryValidationMixin } from "@/mixins/sqlValidator"
import ChartModalMixin from "@/mixins/chart.modal.common"
import JSysenvTabProps from './SysenvPageSvgControl'

export default {
  name: 'sysenv-db-sql-queries-modal',
  components: { ...JSysenvTabProps },
  mixins: [ 
    QueryValidationMixin,
    ChartModalMixin 
  ],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    itemKey: String,
    value: { type: Boolean, default: false }
  },
  data: () => ({
    // Service Instances -------------
    chartLibraryService: null,
    dbSupportService: null,
    // activators --------------------
    msgOpen: false,
    // general -----------------------
    datarows: 30,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    stepper: 0,
    valid: false,
    update: true,


    // SQL Query Tool's data-props
    localItem: {},
    stepperSqlTool: 0,
    resultHeaders: [],
    resultItems: [],
    resultSelected: [],
    validated: { valid: true, message: '' },
  }),
  computed: {
    ...mapGetters(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['general']),

    __C_() { return __C },

    codePropagated: {
      get() { return this.$store.state.sysenv.codePropagated },
      set(value) { this.$store.commit('sysenv/codePropagate', value) }
    },
    modeNew() { return this.formMode == __C.FORM.EDIT_MODE_NEW },
    modeMod() { return this.formMode == __C.FORM.EDIT_MODE_MOD },
    formMode() {
      if (this.item[this.itemKey]) return __C.FORM.EDIT_MODE_MOD
      else return __C.FORM.EDIT_MODE_NEW
    },
    formOpened: {
      get() { return this.value },
      set(val) { this.$emit('input', val) }
    },
    resultItemsAvailable() { return this.resultItems.length > 0 },
    updated: {
      get() { return this.$store.state.sysenv.menuUpdated },
      set(value) { this.$store.commit("sysenv/setMenuStatusUpdated", value) }
    },
    typeName() { return '' },
    helperUrl() { return this.chartItem.ChartNo && this.helper ? require(`../../../assets/helpDoc/logo.png`) : '' },

  },
  watch: {
    formOpened(val) {
      if (!val) return

      this.init()

      if (this.modeNew) {
        this.localItem = {
          phaseCode: this.codePropagated.parentCode == 'ALL' ? '' : this.codePropagated.parentCode,
          groupCode: this.codePropagated.groupCode == 'ALL' ? '' : this.codePropagated.groupCode,
          itemCode: this.codePropagated.code == 'ALL' ? '' : this.codePropagated.code,
          qDataset: '',
          subItem: 0
        }
        this.setChartItem({
          PhaseCode: this.codePropagated.parentCode == 'ALL' ? '' : this.codePropagated.parentCode,
          GroupCode: this.codePropagated.groupCode == 'ALL' ? '' : this.codePropagated.groupCode,
          ItemCode: this.codePropagated.code == 'ALL' ? '' : this.codePropagated.code,
          SubItem: 0
        })

      } else {
        this.localItem = JSON.parse(JSON.stringify(this.item))
        // Set General(Navigation Properties) Information
        this.setChartItem({
          Name: this.localItem.name,
          PhaseCode: this.localItem.phaseCode,
          GroupCode: this.localItem.groupCode,
          ItemCode: this.localItem.itemCode,
          SubItem: this.localItem.subItem
        })
      }

      this.$refs.general.setData(this.formMode)
    }
  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
    this.dbSupportService = new DBSupportService()
  },
  mounted() {
    // this.draggableSize()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem', 'setEmpty']),

    // CRUD Process
    onAction(action) {
      if (action == 'save') this.modeNew ? this.add() : this.edit()
      else if (action == 'delete') this.del()
      else this.close()
    },
    onDBSave() {
      if(this.modeNew) return
    },
    onValidate() {
      this.validateSql(this.localItem.qDataset, 'Y', 'Y', this.datarows)
      .then(res => {
        this.validated = {
          valid: res.valid,
          message: res.message
        }
        if(this.validated.valid) {
          let source = JSON.parse(res.results.jsonString)
          
          this.resultHeaders = Object.keys(source[0]).map(colName => {
            return { type: 'text', text: colName, align: 'center', value: colName, sortable: false }
          })
          // this.resultItems = source.slice(0, 30)
          this.resultItems = source
          this.stepperSqlTool = 0

        } else this.stepperSqlTool = 1
      })
    },

    toJSON() {
      this.validated.message = JSON.stringify(this.resultItems)
      this.stepperSqlTool = 1
    },
    toJS() {
      let jsObjectString_ = []
      this.resultItems.forEach(item => {
        let keys_ = Object.keys(item)
        let jsString_ = []

        keys_.forEach(k => {
          let value_ = typeof item[k] == 'string' ? `"${item[k]}"` : item[k]
          jsString_.push(`${k}:${value_}`)
        })

        jsObjectString_.push(`\n{${jsString_.toString()}}`)
        
      })
      this.validated.message = `[${jsObjectString_.toString()}\n]`
      this.stepperSqlTool = 1
    },

    add() {
      this.localItem.name = this.general.Name
      this.localItem.phaseCode = this.general.PhaseCode
      this.localItem.groupCode = this.general.GroupCode
      this.localItem.itemCode = this.general.ItemCode
      this.localItem.subItem = this.general.SubItem
      this.dbSupportService.putSqlQuery(this.localItem, () => {
        this.close()
        this.$emit("updated", true)
        this.menuUpdated = true
      })
    },
    edit() {
      this.localItem.name = this.general.Name
      this.localItem.phaseCode = this.general.PhaseCode
      this.localItem.groupCode = this.general.GroupCode
      this.localItem.itemCode = this.general.ItemCode
      this.localItem.subItem = this.general.SubItem
      this.dbSupportService.updSqlQuery(this.localItem, res => {
        this.close()
        this.$emit("updated", true)
      })
    },
    del() {
      this.yes = () => {
        this.msgOpen = false
        this.dbSupportService.delSqlQuery(this.localItem.idx, res => {
          this.close()
          this.$emit("updated", true)
          this.menuUpdated = true
        })
        this.yes = () => { }
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Action Approval"
      this.msgInfo.titleDescription = "Important Notification"
      this.msgInfo.message = "Do you want to delete current information?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    init() {
      this.setEmpty()
      this.resultHeaders = []
      this.resultItems = []
      this.stepper = 0
    },
    close() {
      this.formOpened = false
    },
    draggableSize() {
      let minWidth = 60;
      let minHeight = 40;

      // Thresholds
      let FULLSCREEN_MARGINS = -10;
      let MARGINS = 4;

      // End of what's configurable.
      let clicked = null;
      let onRightEdge, onBottomEdge, onLeftEdge, onTopEdge;

      let rightScreenEdge, bottomScreenEdge;

      let preSnapped;

      let b, x, y;

      let redraw = false;

      let pane = document.getElementById('pane');
      let ghostpane = document.getElementById('ghostpane');
      let anotherPane = document.getElementById('anotherPane');
      let sqlToolWrapper = document.getElementById('j-sql-query-tool');

      function setBounds(element, x, y, w, h) {
        element.style.left = x + 'px';
        element.style.top = y + 'px';
        element.style.width = w + 'px';
        element.style.height = h + 'px';
      }

      function hintHide() {
        setBounds(ghostpane, 0, b.top, b.width, b.height);
        setBounds(anotherPane, 0, 0 , b.width, sqlToolWrapper.getBoundingClientRect().height - b.height);

        ghostpane.style.opacity = 0;
      }

      // Mouse events
      pane.addEventListener('mousedown', onMouseDown);
      document.addEventListener('mousemove', onMove);
      document.addEventListener('mouseup', onUp);

      // Touch events	
      pane.addEventListener('touchstart', onTouchDown);
      document.addEventListener('touchmove', onTouchMove);
      document.addEventListener('touchend', onTouchEnd);


      function onTouchDown(e) {
        onDown(e.touches[0]);
        e.preventDefault();
      }

      function onTouchMove(e) {
        onMove(e.touches[0]);		
      }

      function onTouchEnd(e) {
        if (e.touches.length ==0) onUp(e.changedTouches[0]);
      }

      function onMouseDown(e) {
        onDown(e);
        e.preventDefault();
      }

      function onDown(e) {
        calc(e);

        let isResizing = onRightEdge || onBottomEdge || onTopEdge || onLeftEdge;

        clicked = {
          x: x,
          y: y,
          cx: e.clientX,
          cy: e.clientY,
          w: b.width,
          h: b.height,
          isResizing: isResizing,
          isMoving: !isResizing,
          onTopEdge: onTopEdge,
          onLeftEdge: onLeftEdge,
          onRightEdge: onRightEdge,
          onBottomEdge: onBottomEdge
        };
      }

      function canMove() {
        return x > 0 && x < b.width && y > 0 && y < b.height
        && y < 30;
      }

      function calc(e) {
        b = pane.getBoundingClientRect();
        x = e.clientX - b.left;
        y = e.clientY - b.top;

        onTopEdge = y < MARGINS;
        onLeftEdge = x < MARGINS;
        onRightEdge = x >= b.width - MARGINS;
        onBottomEdge = y >= b.height - MARGINS;

        rightScreenEdge = window.innerWidth - MARGINS;
        bottomScreenEdge = window.innerHeight - MARGINS;
      }

      let e;

      function onMove(ee) {
        calc(ee);

        e = ee;

        redraw = true;

      }

      function animate() {

        requestAnimationFrame(animate);

        if (!redraw) return;

        redraw = false;

        if (clicked && clicked.isResizing) {
          if (clicked.onTopEdge) {
            let currentHeight = Math.max(clicked.cy - e.clientY  + clicked.h, minHeight);
            if (currentHeight > minHeight) {
              pane.style.height = currentHeight + 'px';
              pane.style.top = e.clientY + 'px';	
            }
          }

          hintHide();

          return;
        }
        // style cursor
       if (onBottomEdge || onTopEdge) {
          pane.style.cursor = 'ns-resize';
        } else {
          pane.style.cursor = 'default';
        }
      }

      animate();

      function onUp(e) {
        calc(e);

        if (clicked && clicked.isMoving) {
          // Snap
          let snapped = {
            width: b.width,
            height: b.height
          };

          if (b.top < MARGINS) {
            // hintTop();
            setBounds(pane, 0, 0, window.innerWidth, sqlToolWrapper.getBoundingClientRect().height / 2);
            preSnapped = snapped;
          } else {
            preSnapped = null;
          }

          hintHide();

        }
        clicked = null;

      }
    }
  }
}
</script>
