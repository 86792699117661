<template>
  <div class="animated fadeIn">
    <div class="table-container">
      <div class="list-wrapper">
        <div>
          <div class="j_datagrid__header type01">
            <div class="header__title">
              <h5>
                {{ $store.state.sysenv.codePropagated.name }}
                <small>{{ $store.state.sysenv.codePropagated.parentName }}</small>
              </h5>
            </div>

            <div class="header__right">
              <v-flex>
                <button class="jcon_add" @click="setAdd"></button>
                <div class="j_search type01">
                  <input v-model="search" type="search" name="q" placeholder="Filter and search" />
                  <button type="submit">
                    <v-icon>mdi-magnify</v-icon>
                  </button>
                </div>
              </v-flex>
            </div>
          </div>

          <j-data-grid
            v-model="selected"
            hide-actions
            @sorted="onSorted"
            @filtered="onFiltered"
            :headers="headers"
            :items="items"
            :search="search"
          >
            <template #items="props">
              <tr :active="props.selected" @click="propStatus(props)">
                <td style="text-align: center">{{ props.item.no }}</td>
                <td style="text-align: center">{{ props.item.idx }}</td>
                <td>{{ props.item.name }}</td>
                <td v-if="phaseAvailable">{{ props.item.phaseName }}</td>
                <td v-if="phaseAvailable">{{ props.item.groupName }}</td>
                <td v-if="itemAvailable">{{ props.item.itemName }}</td>
                <td>{{ props.item.subItemName }}</td>
                <td>{{ props.item.desc }}</td>
              </tr>
            </template>
          </j-data-grid>
        </div>
      </div>

      <sysenv-modal 
        v-model="formOpened"
        item-key="idx"
        :item="selectedItem"
        @close="onClose"
        @updated="onUpdated"
      />

    </div>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { DBSupportService } from '@/services'
import SysenvModal from './modal/SysenvDbSqlQueries.modal'

export default {
  name: 'sysenv-chartlib',
  components: {
    SysenvModal,
  },
  created() {
    this.dbSupportService = new DBSupportService()
  },
  data: () => ({
    dbSupportService: null,
    formOpened: false,
    keyValue: '',
    search: '',
    selected: [],
    items: [],
    selectedItem: {},
    selectedPrevItem: {}
  }),
  watch: {
    codePropagated: {
      handler(val) { this.onUpdated() },
      deep: true
    }
  },
  computed: {
    codePropagated: {
      get() { return this.$store.state.sysenv.codePropagated },
      set(val) { this.$store.commit('sysenv/codePropagate', val) }
    },
    headers() {
      return this.phaseAvailable ? [
        { type: 'conf', text: 'No', align: 'center', value: 'no', sortable: false, width: 50, },
        { type: 'text', text: 'IDX', align: 'center', value: 'idx', sortable: false, width: 50, },
        { type: 'text', text: 'Title', align: 'left', value: 'name', sortable: false, width: 250, },
        { type: 'text', text: 'Phase', align: 'left', value: 'phaseName', sortable: false, width: 120, },
        { type: 'text', text: 'Menu Group', align: 'left', value: 'groupName', sortable: false, width: 150, },
        { type: 'text', text: 'Menu Item', align: 'left', value: 'itemName', sortable: false, width: 180, },
        { type: 'text', text: 'Sub-Item', align: 'left', value: 'subItemName', sortable: false, width: 120, },
        { type: 'text', text: 'Desciption', align: 'left', value: 'desc', sortable: false },
      ] : [
        { type: 'conf', text: 'No', align: 'center', value: 'no', sortable: false, width: 50, },
        { type: 'text', text: 'IDX', align: 'center', value: 'idx', sortable: false, width: 50, },
        { type: 'text', text: 'Title', align: 'left', value: 'name', sortable: false, width: 250, },
        { type: 'text', text: 'Menu Item', align: 'left', value: 'itemName', sortable: false, width: 180, },
        { type: 'text', text: 'Sub-Item', align: 'left', value: 'subItemName', sortable: false, width: 120, },
        { type: 'text', text: 'Desciption', align: 'left', value: 'desc', sortable: false },
      ]
    },

    phaseAvailable() { return this.codePropagated.parentCode == 'ALL' ? true : false },
    itemAvailable() { return this.codePropagated.code == 'ALL' ? true : false },
  },
  methods: {
    setAdd() {
      this.selectedItem = {}
      this.selectedItem.idx = ""
      this.formOpened = true
    },
    getSqlQueries() {
      this.dbSupportService.getSqlQueries(
        this.codePropagated.parentCode,
        this.codePropagated.groupCode,
        this.codePropagated.code,
        'ALL',
        (res) => {
          if(!res) res = []
          this.items = res
        }
      )
    },
    onClose() {
      this.keyValue = null
    },
    onFiltered(items) {
      let reNumbering = 0
      items.forEach(_item => {
        this.items.find(
          _item_ => _item_.ChartNo == _item.ChartNo
        ).no = ++reNumbering
      })
    },
    onSorted(items) {
      items.forEach((item, index) => {
        item.no = index + 1
      })
    },
    onUpdated() {
      this.getSqlQueries()
    },
    propStatus(props) {
      this.selectedItem = Object.assign({}, props.item)

      if (this.selectedPrevItem.length > 0) {
        if (this.selectedPrevItem[0].idx == props.item.idx) {
          this.formOpened = true
          return
        } else {
          let _selected = props.item
          this.selected = [_selected]
        }
      } else {
        props.selected = !props.selected
      }
      this.selectedPrevItem = [...this.selected]
      this.formOpened = true
    },
  },
}
</script>
